//fonts
$font-lato: 'lato';
$font-roboto: 'roboto';

// font weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;

//colors
$green1: #76e291;
$gray1:#415262;


$gray2: #676F8F;
$gray3: #A9B1D1;
$gray4: #EDEFF5;
$gray5: #FAFAFC;
$gray6: #D4D5E8;
$gray7: #C1C6DB;
$gray8: #6E7799;
$lila1: #6F7DFF;
$lila2: #939DFF;
$red: #EF3340;
$red2: #FC4E55;
$pink: #FF888D;
$pink2: #F55D68;
$pink3: #FFD7DA;
$pink4: #FEE7E9;
$blue: #008fe0;
$purple: #b32276;

$green: #92D66F;
$green2: #CFEDC0;
$green3: #E9F7E2;
$orange: #FFAF3A;
$orange2: #FFEFD8;
$orange3: #FFAF3A;
$primary: $blue;
$secondary: $purple;


@function width-column($columns, $total) {
  @return (100% / $total) * $columns;
}

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin icon-font {
  font-family: 'iconrimac' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.center_elements{
  display: flex;
  align-items: center;
  justify-content: center;
}